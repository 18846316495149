import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const PageContainer = styled.div`
  padding-top: 9rem;
  height: 100vh;

  .container_wrapper {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      align-items: baseline;
    }
  }

  .messagedata {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-family: LibreBaskervilleRegular;
      font-weight: 300;
      font-size: 3rem;
      line-height: 3.8rem;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 2rem;
        line-height: 3rem;
      }
    }

    p {
      margin: 0.25rem auto;
      line-height: 2rem;
      text-align: center;
    }
    .arabic_content {
      font-family: CairoRegular;
      padding-top: 20px;
    }
  }

  .explorebutton {
    text-align: center;
    a {
      color: #000;
    }
  }
`;

export const FigureImage = styled.figure`
  margin: 0px;
`;

export const NewsLetterImage = styled(LazyLoadImage)`
  max-width: 100%;
  padding-top: 4.375rem;
`;
