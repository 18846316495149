import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import { PageContainer, FigureImage, NewsLetterImage } from '../components/styles/404.styled';
import { ColumnTwelveColumnMdSix } from '../components/styles/Column.styled';
import { Container } from '../components/styles/Container.styled';
import { Row } from '../components/styles/Row.styled';
import { ExploreButton, Button } from '../components/styles/ExploreButton.styled';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function NotFound() {
  const query = useStaticQuery(graphql`
    {
      umbraco {
        allWeddingsSettings {
          items {
            contentTypeAlias
            copyrightText
            facebook
            footerText
            mainNavigation {
              content {
                ... on Umbraco_NavigationItem {
                  link {
                    name
                    url
                  }
                }
              }
            }
            links {
              name
              url
            }
            subscribeLink {
              name
              url
            }
            instagram
            makeAnEnquiryLink {
              name
              url
            }
            bottomLinks {
              name
              url
            }
          }
        }
      }
    }
  `);
  const settings = query.umbraco.allWeddingsSettings.items[0];
  return (
    <Layout>
      <Header settings={settings} currentPage={{ url: '/en/' }} lang="en" className="white" />
      <PageContainer>
        <Container className="container_wrapper">
          <Row>
            <ColumnTwelveColumnMdSix>
              <FigureImage>
                <NewsLetterImage src="https://media.umbraco.io/dev-dwtc/tqnjpybu/contact-feat.jpg" />
              </FigureImage>
            </ColumnTwelveColumnMdSix>
            <ColumnTwelveColumnMdSix className="messagedata">
              <h2>Sorry, we can't find the page you are looking for.</h2>
              <h2 className="arabic_content">عذراً، لم نتمكّن من العثور على ما تبحث عنه</h2>
              <ExploreButton className="explorebutton">
                <Button to="/">Home</Button>
              </ExploreButton>
            </ColumnTwelveColumnMdSix>
          </Row>
        </Container>
      </PageContainer>
      <Footer settings={settings} />
    </Layout>
  );
}
